import { useAuth0 } from '@auth0/auth0-react';
import * as Constants from "../../constants";
import { useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import CriticalAlert from '../../components/swe/critical-alert';
import useGlobalState from "../../hooks/useGlobalState";

export const NoEligibleCustomer = () => {
    const { isAuthenticated, user, logout } = useAuth0();
    const { globalState } = useGlobalState();
    const navigate = useNavigate();
    const alertRef = useRef(null);

    if (Constants.DEBUG) { console.log("%cDocument is ineligible: user: %o", 'color: #f96;', user); }

    useEffect(() => {
        (async () => {
            if (!isAuthenticated) {
                navigate("/unauthorised", { replace: true });
            } else {
                let mfaAvailable = globalState.userCompletedMFA;
                if (typeof mfaAvailable === 'undefined' || (user && user.email_verified === false)) {
                    if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "page blocked. Redirecting to uplift"); }
                    navigate("/ip-uplift", { replace: true });
                }
            }
        })();
    })

    useEffect(()=>{
        alertRef?.current?.focus();
    }, []);

    useEffect(() => {
        (async () => {  
          if (Constants.DEBUG) { console.log('Hard stop! Uplift process terminated. Logging user out of QDI session.'); }
    
          logout({
            localOnly: true,
          });
    
          localStorage.clear();
          sessionStorage.clear();  
        })();
      }, []);

    return (<>
        {isAuthenticated &&
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Identity</h1>

                <div tabIndex={-1} ref={alertRef}>
                    <CriticalAlert heading="Document is ineligible."
                        message={
                            <>
                                <p>The document you are attempting to verify is ineligible.</p> 
                                <p>If you believe this to be incorrect, <a href={Constants.CONTACT_US_URL}>contact us</a> to continue setting up your Queensland Digital Identity.</p>
                            </>                                
                        } 
                    />
                </div>

            </div>
        }
    </>)
};

export default NoEligibleCustomer;
